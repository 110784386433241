import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

// const Appdefs = window.cccisd.appDefs;

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [
        {
            url: '/welcome',
            label: 'HOME',
        },
        { url: '/welcome', label: 'ABOUT' },
        {
            url: '/resources',
            label: 'RESOURCES',
        },
        {
            url: '/welcome',
            label: 'HELP',
        },
    ];
    // Dont need the appdef navs right now, since site is not using auth or roles
    // try {
    //     primaryNavLinks.push(Appdefs.navs.find(n => n.handle === 'primaryNav').nav);
    // } catch (e) {
    //     // meh, leave it empty
    // }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            {...(noLogoLink ? { logoLink: null } : {})}
            showUserNav={false}
            showHomeAction={false}
            hasAlertAction={false}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
